/**
 * Plans page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Blockplans from "../../components/blockplans"
import Hero from "../../components/hero"
import Layout from "../../components/layout"
import Sectionctafooter from "../../components/sectionctafooter"
import Sectionh1content from "../../components/sectionh1content"
import Seo from "../../components/seo"
import "../../styles/pages/plans.scss"

/* Page function declaration */
const PlansPage = ({ data }) => {

  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPagePlans

  //CTA Footer page CONTACT
  var ctaFooterContact = data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "contact"
  })

  return (
    <Layout className="plans">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Section Hero */}
      <Hero classNamePage="plans" colorHero="white" colorShape="bluegreynormal" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneralPlans} contentH1={dataAcf.contentGeneralPlans} classNameH1="plans white" vc="true" />

      <section className="section-plans">
        <div className="content-plans">
          <h2 className="title-level-2">{dataAcf.titleDescriptionPlans}</h2>
          <div className="text-plans" dangerouslySetInnerHTML={{__html:dataAcf.contentDescriptionPlans}}/>
          <Blockplans plans={data.plansVC} saving={dataAcf.flagSaving} />
        </div>
        <div className="note-plans" dangerouslySetInnerHTML={{__html:dataAcf.notePlans}}/>
      </section>

     {/* Section CtaFooter */}
     {ctaFooterContact.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="contact" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="bluegrey-vc" />
      })}
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "plans"}) {
      acfPagePlans {
        contentDescriptionPlans
        contentGeneralPlans
        flagSaving
        notePlans
        titleDescriptionPlans
        titleGeneralPlans
      }
      seo {
        metaDesc
        title
      }
    }
    plansVC: allWpPlan(sort: {fields: id, order: DESC}) {
      nodes {
        acfPostPlan {
          priceMonthly
          priceYearly
          repeaterFeaturesPlan {
            featurePlan
          }
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`
/* Export page informations */
export default PlansPage



