/**
 * Const with params element
 * Card plan component
 * Display a card with plan's information
 */
/* Import section */
import React from "react"
import Ctabutton from "./ctabutton"
import Iconcheck from "../images/icons/iconcheck.inline.svg"
import "../styles/components/cardplan.scss"


/* Declaration function */
const Cardplan = ( { titlePlan, typePrice, pricemonthlyPlan, priceyearlyPlan, featuresPlan } ) => {

  var pricePlan = (typePrice === "monthly") ? pricemonthlyPlan : priceyearlyPlan

  return (
    <div className="card-plan">
      <h3 className="title-level-2">{titlePlan}</h3>
      <div className="separator"></div>
      <h4><sup>$</sup>{pricePlan}</h4>
      <p>{typePrice === "monthly" ? "monthly" : "yearly"}</p>
      <div className="list-features">
        <ul className={`list-virtualcare ${ typePrice }` }>
          {typePrice === "yearly" && <li className="item-saving"><span className="check"><Iconcheck className="svg-icon-check"/></span><span className="item">Save 17% by paying annually</span></li>}
          {featuresPlan.map((feature, index) =>
            <li key={index}><span className="check"><Iconcheck className="svg-icon-check"/></span><span className="item">{feature.featurePlan}</span></li>
          )}
        </ul>
      </div>
      <div className="cta-bottom-card">
        <Ctabutton ctaLabel="Sign Up" ctaLink="/virtual-care/sign-up" otherStyle="signup-pricing" />
      </div>
    </div>
  );
}


/* Export function */
export default Cardplan
