/**
 * Class element
 * Block Plans component
 * Display a block of plans according the type of price (monthly vs yearly)
 */
/* Import section */
import React, { Component } from "react"
import Cardplan from "../components/cardplan"
import "../styles/components/blockplans.scss"

/* Declaration class */
class Blockplans extends Component {

  constructor(props) {
    super(props)
    this.state = {
      checkedPrice: false
    }
  }

  togglePlan = (event) => {
    this.setState({
      checkedPrice: event.target.checked
    });
  }

  render () {
    var typePriceSelected = (this.state.checkedPrice === true) ? "yearly" : "monthly"

    return (
      <div className="block-plans">
        <div className="nav-plans">
          <div className="flag-saving">
            <p>{this.props.saving}</p>
          </div>
          <div className="box-switcher">
            <span className="switcher">
              <label htmlFor="switch-price">
                <input id="switch-price" name="view" type="checkbox" checked={this.state.checkedPrice} onChange={this.togglePlan} />
              </label>
            </span>
          </div>
        </div>
        <div className="list-plans">
          {this.props.plans.nodes.map( (plan, index)  => (
            <Cardplan
              key={`plan-${ index }`}
              typePrice={typePriceSelected}
              titlePlan={plan.title}
              pricemonthlyPlan={plan.acfPostPlan.priceMonthly}
              priceyearlyPlan={plan.acfPostPlan.priceYearly}
              featuresPlan={plan.acfPostPlan.repeaterFeaturesPlan}
            />
            )
          )}
        </div>

      </div>
    )
  }
}

/* Export function */
export default Blockplans
